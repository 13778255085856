import React, { PureComponent } from "react";
import uniqueId from "lodash/uniqueId";
import { Linear, TimelineMax, TweenLite } from "gsap/dist/gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import { mediaMedium } from "./styles/variables";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  pointer-events: none;
`;

const Display = styled.div`
  display: none;
  position: absolute;
  top: -4%;
  bottom: -4%;
  left: 0;
  width: ${(props) => props.svgWidth ?? "420px"};
  max-height: 120%;
  ${(props)=>props.theme.displayProps && props.theme.displayProps}

  .line {
    transition: 0.2s ease;
  }

  @media (${mediaMedium}) {
    display: block;
    min-height: 100%;
  }
`;

const Trigger = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  top: 0;
  left: 0;
`;

class AnimatedLineSvg extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      controllerSM: null,
      sceneSM: null,
      sceneSMSide: null,
      observerObject: null,
      id: uniqueId("svg-ani-")
    };
  }

  async componentDidMount() {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    if (vw > 550 && this.props.static !== true) {
      this.ScrollMagic = await import("scrollmagic");
      TweenLite.defaultOverwrite = "none";

      ScrollMagicPluginGsap(this.ScrollMagic, TweenLite, TimelineMax);

      this.initAnimation();
    }
  }

  componentWillUnmount() {
    const { controllerSM, sceneSM } = this.state;

    if (controllerSM) controllerSM.destroy(true);
    if (sceneSM) sceneSM.destroy(true);
  }

  initAnimation() {
    try {
      const svg = document.getElementById(this.state.id);
      const { duration = 1000, triggerOffset = 300 } = this.props;

      if (!svg) {
        return "";
      }

      let lineLength;
      svg.querySelectorAll(".line").forEach((item) => {
        lineLength = item.getTotalLength();
        item.style.strokeDasharray = `${lineLength}px`;
        item.style.strokeDashoffset = `${lineLength}px`;
      });

      const tweenSM = TweenLite.fromTo(
        `#${this.state.id} .line`,
        1,
        {
          strokeDashoffset: lineLength,
          ease: Linear.easeInOut
        },
        {
          strokeDashoffset: 0,
          ease: Linear.easeIn
        }
      );

      // init controller
      const controllerSM = new this.ScrollMagic.Controller();

      // build scene
      const sceneSM = new this.ScrollMagic.Scene({
        triggerElement: `#${this.state.id}-trigger-top`,
        duration: duration,
        offset: triggerOffset
      })
        .setTween(tweenSM)
        .addTo(controllerSM);

      this.setState({ controllerSM, sceneSM });
    } catch (e) {}
  }

  render() {
    return (
      <Wrapper>
        <Trigger id={`${this.state.id}-trigger-top`} />
        <Display svgWidth={this.props.svgWidth} id={this.state.id} theme={this.props.theme}>
          {this.props.children}
        </Display>
      </Wrapper>
    );
  }
}

export default AnimatedLineSvg;
